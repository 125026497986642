import React from "react";
import "./Cards.css";
// import CardItem from "./CardItem";
import { Card } from "react-bootstrap";
function MitraBuilding() {
  return (
    <>
      <div className="card-container-mitra">
        <h1 className="card-title">Mitra Building</h1>
        <div class="card-links-mitra">
          <div className="card-link-wrapper-mitra">
            <div class="card-link-items-mitra">
              <Card style={{ border: 0, background: "none" }}>
                <Card.Body>
                  <Card.Img
                    style={{ width: 180 }}
                    variant="top"
                    src="/images/homepage/mitraBuilding/Menara Kebon Jeruk.png"
                  />
                </Card.Body>
              </Card>
            </div>
            <div class="card-link-items-mitra">
              <Card style={{ border: 0, background: "none" }}>
                <Card.Body>
                  <Card.Img
                    style={{ width: 180 }}
                    variant="top"
                    src="/images/homepage/mitraBuilding/Paragon Village.png"
                  />
                </Card.Body>
              </Card>
            </div>
            <div class="card-link-items-mitra">
              <Card style={{ border: 0, background: "none" }}>
                <Card.Body>
                  <Card.Img
                    style={{ width: 180 }}
                    variant="top"
                    src="/images/homepage/mitraBuilding/Teluk Intan Topaz.png"
                  />
                </Card.Body>
              </Card>
            </div>
            <div class="card-link-items-mitra">
              <Card style={{ border: 0, background: "none" }}>
                <Card.Body>
                  <Card.Img
                    style={{ width: 180 }}
                    variant="top"
                    src="/images/homepage/mitraBuilding/Teluk Intan Sapphire.png"
                  />
                </Card.Body>
              </Card>
            </div>
            <div class="card-link-items-mitra">
              <Card style={{ border: 0, background: "none" }}>
                <Card.Body>
                  <Card.Img
                    style={{ width: 180 }}
                    variant="top"
                    src="/images/homepage/mitraBuilding/MTHR.png"
                  />
                </Card.Body>
              </Card>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default MitraBuilding;
