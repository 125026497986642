import React from "react";
import "./Cards.css";
// import CardItem from "./CardItem";
import { Card } from "react-bootstrap";
function MitraMerchant() {
  return (
    <>
      <div className="card-container">
        <h1 className="card-title">Mitra Merchant</h1>
        <div class="card-links-mitra">
          <div className="card-link-wrapper-mitra">
            <div class="card-link-items-mitra">
              <Card style={{ border: 0, background: "none" }}>
                <Card.Body>
                  <Card.Img
                    style={{ width: 180 }}
                    variant="top"
                    src="/images/homepage/mitraMerchant/1.png"
                  />
                </Card.Body>
              </Card>
            </div>
            <div class="card-link-items-mitra">
              <Card style={{ border: 0, background: "none" }}>
                <Card.Body>
                  <Card.Img
                    style={{ width: 180 }}
                    variant="top"
                    src="/images/homepage/mitraMerchant/2.png"
                  />
                </Card.Body>
              </Card>
            </div>
            <div class="card-link-items-mitra">
              <Card style={{ border: 0, background: "none" }}>
                <Card.Body>
                  <Card.Img
                    style={{ width: 180 }}
                    variant="top"
                    src="/images/homepage/mitraMerchant/3.png"
                  />
                </Card.Body>
              </Card>
            </div>
            <div class="card-link-items-mitra">
              <Card style={{ border: 0, background: "none" }}>
                <Card.Body>
                  <Card.Img
                    style={{ width: 180 }}
                    variant="top"
                    src="/images/homepage/mitraMerchant/4.png"
                  />
                </Card.Body>
              </Card>
            </div>
            <div class="card-link-items-mitra">
              <Card style={{ border: 0, background: "none" }}>
                <Card.Body>
                  <Card.Img
                    style={{ width: 180 }}
                    variant="top"
                    src="/images/homepage/mitraMerchant/5.png"
                  />
                </Card.Body>
              </Card>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default MitraMerchant;
