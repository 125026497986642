import React from 'react';
import '../../App.css';
import Cards from '../Cards';
import HeroSection from '../HeroSection';
import Footer from '../Footer';
import WhySection from '../WhySection';
import MitraBuilding from '../MitraBuilding';
import MitraMerchant from '../MitraMerchant';
import PaymentMethod from '../PaymentMethod';
import Testimoni from '../Testimoni';

function Home() {
  return (
    <>
      <HeroSection />
      <Cards />
      <MitraBuilding />
      <WhySection />
      <MitraMerchant />
      <Testimoni />
      <PaymentMethod />
      <Footer />
    </>
  );
}

export default Home;
