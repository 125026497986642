import React, { useState } from "react";
import { Button } from "./Button";
import { Link } from "react-router-dom";
import "./Navbar.css";

function Navbar() {
  const [click, setClick] = useState(false);

  const handleClick = () => setClick(!click);
  const closeMobileMenu = () => setClick(false);

  return (
    <>
      <nav className="navbar">
        <div className="navbar-container">
          <Link to="/" className="navbar-logo" onClick={closeMobileMenu}>
            <img src="/images/yipy-logo-red.png" alt="logo" width={65} />
          </Link>
          <div className="menu-icon" onClick={handleClick}>
            <i className={click ? "fas fa-times" : "fas fa-bars"} />
          </div>
          <ul className={click ? "nav-menu active" : "nav-menu"}>
            <li className="nav-item">
              <div class="dropdown">
                <button class="dropbtn">
                  Layanan &nbsp; <i class="fa fa-caret-down"></i>
                </button>
                <div class="dropdown-content">
                  <a href=" ">
                    <Link
                      to="/layanan resident"
                      className="nav-links"
                      onClick={closeMobileMenu}
                    >
                      Yipy Resident
                    </Link>
                  </a>
                  <a href=" ">
                    <Link
                      to="/layanan merchant"
                      className="nav-links"
                      onClick={closeMobileMenu}
                    >
                      Yipy Merchant
                    </Link>
                  </a>
                  <a href=" ">
                    <Link
                      to="/layanan staff"
                      className="nav-links"
                      onClick={closeMobileMenu}
                    >
                      Yipy Staff
                    </Link>
                  </a>
                </div>
              </div>
            </li>
            <li className="nav-item">
              <div class="dropdown">
                <button class="dropbtn">
                  Gabung Mitra &nbsp; <i class="fa fa-caret-down"></i>
                </button>
                <div class="dropdown-content">
                  <a href=" ">
                    <Link
                      to="/mitra building"
                      className="nav-links"
                      onClick={closeMobileMenu}
                    >
                      Mitra Building
                    </Link>
                  </a>
                  <a href=" ">
                    <Link
                      to="/mitra merchant"
                      className="nav-links"
                      onClick={closeMobileMenu}
                    >
                      Mitra Merchant
                    </Link>
                  </a>
                </div>
              </div>
            </li>
            <li className="nav-item">
              <Link to="/blog" className="nav-links" onClick={closeMobileMenu}>
                Blog
              </Link>
            </li>
            <li className="nav-item">
              <Link to="/promo" className="nav-links" onClick={closeMobileMenu}>
                Promo
              </Link>
            </li>
            <li className="nav-item">
              <Link
                to="/pengguna"
                className="nav-links"
                onClick={closeMobileMenu}
              >
                Pengguna
              </Link>
            </li>
            <li className="nav-item">
              <Link
                to="/hubungi kami"
                className="nav-links"
                onClick={closeMobileMenu}
              >
                Hubungi Kami
              </Link>
            </li>
            <li>
              <Link
                to="/download"
                className="nav-links"
                onClick={closeMobileMenu}
              >
                <Button
                  buttonStyle="btn--primary"
                  buttonSize="btn--small"
                  buttonWeight="bolder"
                >
                  Download
                </Button>
              </Link>
            </li>
          </ul>
        </div>
      </nav>
    </>
  );
}

export default Navbar;
