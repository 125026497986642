import React from "react";
import "../../../App.css";
import "../../HeroSection.css";

function HeroSection() {
  return (
    <div className="hero-container-kontak">
      {/* <video src='/videos/video-1.mp4' autoPlay loop muted /> */}
      <h1>Halo, ada yang bisa kami bantu?</h1>
    </div>
  );
}

export default HeroSection;
