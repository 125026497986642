import React from "react";
import "../../../Cards.css";
import { Card, Image } from "react-bootstrap";
function Content() {
  return (
    <>
      <div className="card-container">
        <div class="card-links">
          <div className="card-link-wrapper">
            <div class="card-link-items">
              <Card.Body>
                <Card.Text>
                  <Image
                    fluid
                    src="/images/homepage/layanan/staff/1.png"
                    rounded
                  />
                </Card.Text>
              </Card.Body>
            </div>
            <div class="card-link-items">
              <Card.Body>
                <Card.Title className="more-info-title">
                  Digital Absences Management
                </Card.Title>
                <Card.Text>
                  <div className="more-info-text-content">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sit
                    lectus justo vulputate posuere. Mattis viverra vitae, metus
                    amet. Pretium, pulvinar amet, enim adipiscing lorem orci.
                  </div>
                </Card.Text>
              </Card.Body>
            </div>
          </div>
        </div>
      </div>
      <div className="card-container">
        <div class="card-links">
          <div className="card-link-wrapper">
            <div class="card-link-items">
              <Card.Body>
                <Card.Title className="more-info-title">
                  Real Time Task Notification
                </Card.Title>
                <Card.Text>
                  <div className="more-info-text-content">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sit
                    lectus justo vulputate posuere. Mattis viverra vitae, metus
                    amet. Pretium, pulvinar amet, enim adipiscing lorem orci.
                  </div>
                </Card.Text>
              </Card.Body>
            </div>
            <div class="card-link-items">
              <Card.Body>
                <Card.Text>
                  <Image
                    fluid
                    src="/images/homepage/layanan/staff/2.png"
                    rounded
                  />
                </Card.Text>
              </Card.Body>
            </div>
          </div>
        </div>
      </div>
      <div className="card-container">
        <div class="card-links">
          <div className="card-link-wrapper">
            <div class="card-link-items">
              <Card.Body>
                <Card.Text>
                  <Image
                    fluid
                    src="/images/homepage/layanan/staff/3.png"
                    rounded
                  />
                </Card.Text>
              </Card.Body>
            </div>
            <div class="card-link-items">
              <Card.Body>
                <Card.Title className="more-info-title">
                  Easiest Work Report
                </Card.Title>
                <Card.Text>
                  <div className="more-info-text-content">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sit
                    lectus justo vulputate posuere. Mattis viverra vitae, metus
                    amet. Pretium, pulvinar amet, enim adipiscing lorem orci.
                  </div>
                </Card.Text>
              </Card.Body>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Content;
