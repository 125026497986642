import React from 'react';
import '../../../App.css';
import Footer from '../../Footer';
import HeroSection from './HeroSection';

function Download() {
  return (
    <>
      <HeroSection />
      <Footer />
    </>
  );
}

export default Download;
