import React from 'react';
import '../../../../App.css';
import Footer from '../../../Footer';
import HeroSection from './HeroSection';
import MoreInfo from './MoreInfo';
import Payments from './Payments';

function LayananResident() {
  return (
    <>
      <HeroSection />
      <Payments />
      <MoreInfo />
      <Footer />
    </>
  );
}

export default LayananResident;
