import React from 'react';
import { Link } from 'react-router-dom';

function CardItemV2(props) {
  return (
    <>
      <li className='cards__item__v2'>
        <Link className='cards__item__link-v2' to={props.path}>
          <figure className='cards__item__pic-wrap-v2'>
            <img
              className='cards__item__img-v2'
              alt=' '
              src={props.src}
            />
          </figure>
          <div className='cards__item__info'>
            <h5 className='cards__item__text'>{props.text}</h5>
            <p className='cards__item__name'>{props.name}</p>
            <p className='cards__item__stat'>{props.status}</p>
            <p className='cards__item__testi'>“{props.testimoni}”</p>
          </div>
        </Link>
      </li>
    </>
  );
}

export default CardItemV2;
