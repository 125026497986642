import React from "react";
import "../../Cards.css";
import "../../../App.css";
import { Image } from "react-bootstrap";
function Content() {
  return (
    <>
      <div className="card-container">
        <h1 className="card-title">Sahabat Hunian Yipy</h1>
        <p className="cards__item__text__pengguna">
          Mereka yang selalu ada bersama Yipy untuk membangun ekosistem hunian
          digital yang lebih baik.
        </p>
        <div class="card-links">
          <div className="card-link-wrapper">
            <div class="card-link-items">
              <div
                className="PenggunaContainer"
                style={{
                  background: `url(/images/homepage/pengguna/image/bg1.png)`,
                }}
              >
                <div className="row">
                  <div className="col">
                    <div className="title-blog">Penghuni</div>
                    <div className="period-promo">
                      Kamu yang tinggal di hunian.
                    </div>
                    <Image
                      fluid
                      src="/images/homepage/pengguna/image/1.png"
                      rounded
                      width={280}
                      style={{ marginTop: 70 }}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="card-link-items">
              <div
                className="PenggunaContainer"
                style={{
                  background: `url(/images/homepage/pengguna/image/bg2.png)`,
                }}
              >
                <div className="row">
                  <div className="col">
                    <div className="title-blog">Pengelola</div>
                    <div className="period-promo">
                      Petugas yang bisa sat set sat set melayani keperluan
                      hunian kamu.
                    </div>
                    <Image
                      fluid
                      src="/images/homepage/pengguna/image/2.png"
                      rounded
                      width={280}
                      style={{ marginTop: 50 }}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="card-link-items">
              <div
                className="PenggunaContainer"
                style={{
                  background: `url(/images/homepage/pengguna/image/bg3.png)`,
                }}
              >
                <div className="row">
                  <div className="col">
                    <div className="title-blog">Penjual</div>
                    <div className="period-promo">
                      Mau belanja? tenang, ada mereka yang siap menuhin semua
                      kebutuhan hunian kamu.
                    </div>
                    <Image
                      fluid
                      src="/images/homepage/pengguna/image/3.png"
                      rounded
                      width={280}
                      style={{ marginTop: 40 }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Content;
