import React from "react";
import "./Cards.css";
// import CardItem from "./CardItem";
import { Card, Image } from "react-bootstrap";
function Cards() {
  return (
    <>
      <div className="card-container">
        <div class="card-links">
          <div className="card-link-wrapper">
            <div class="card-link-items">
              <Card.Body>
                <Card.Text>
                  <Image
                    fluid
                    src="/images/homepage/Sahabat_Yipy.jpg"
                    rounded
                  />
                </Card.Text>
              </Card.Body>
            </div>
            <div class="card-link-items">
              <Card.Body>
                <Card.Title className="more-info-title">
                  Teruntuk Sahabat <font color={"#E12029"}>Yipy</font>
                </Card.Title>
                <Card.Text className="more-info-text-content">
                  <p>
                    Hari gini engga perlu ribet buat nyari solusi untuk memenuhi
                    segala kebutuhanmu. Karena dengan apikasi Yipy, semua yang
                    Kamu butuhkan sudah terintegrasi dalam satu aplikasi.
                  </p>
                  <div>
                    <div
                      style={{
                        paddingTop: 30,
                        paddingBottom: 20,
                        fontWeight: 700,
                        alignContent: "center",
                        display: "flex",
                      }}
                    >
                      <Image
                        style={{ width: 58, height: 58, marginRight: 20 }}
                        fluid
                        src="/images/homepage/ic-bayaripl.png"
                      />{" "}
                      <span style={{ marginTop: 15 }}>
                        Bisa Buat Bayar Tagihan IPL
                      </span>
                    </div>
                    <div
                      style={{
                        paddingTop: 30,
                        paddingBottom: 20,
                        fontWeight: 700,
                        alignContent: "center",
                        display: "flex",
                      }}
                    >
                      <Image
                        style={{ width: 58, height: 58, marginRight: 20 }}
                        fluid
                        src="/images/homepage/ic-service.png"
                      />{" "}
                      <span style={{ marginTop: 15 }}>
                        Bisa Laporkan Kendala Hunian
                      </span>
                    </div>
                    <div
                      style={{
                        paddingTop: 30,
                        paddingBottom: 20,
                        fontWeight: 700,
                        alignContent: "center",
                        display: "flex",
                      }}
                    >
                      <Image
                        style={{ width: 58, height: 58, marginRight: 20 }}
                        fluid
                        src="/images/homepage/ic-announc.png"
                      />{" "}
                      <span style={{ marginTop: 15 }}>
                        Bisa Dapat Informasi Terkini
                      </span>
                    </div>
                  </div>
                </Card.Text>
              </Card.Body>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Cards;
