import React from "react";
import "../../../Cards.css";
function Payments() {
  return (
    <>
      <div className="card-container-payments" />
    </>
  );
}

export default Payments;
