
import React from 'react';
import '../../../Cards.css';
import CardItemV3 from '../../../CardItemv3';

function Testimoni() {
  return (
    <div className='cards'>
      <h1>Cerita happy mereka bareng Yipy</h1>
      <div className='cards__container__v3'>
        <div className='cards__wrapper'>
          <ul className='cards__items'>
            <CardItemV3
              src='images/homepage/testimoni/testi2.png'
              name='Natasya I.'
              status='Pengelola SIP Apartment'
              testimoni='Yipy memudahkan kami selaku
              pihak BM dalam mengelola
              semua aspek manajemen. CS
              Yipy juga sangat responsif ketika
              kami ada masalah.'
              // path='/products'
            />
            <CardItemV3
              src='images/homepage/testimoni/testi3.png'
              name='Rudi Setiawan'
              status='Staff Technician'
              testimoni='Berkat Yipy saya jadi lebih
              mudah melaporkan hasil
              pekerjaan saya. Hasil laporan
              pekerjaan saya juga jadi lebih
              rapi.'
              // path='/sign-up'
            />
          </ul>
        </div>
      </div>
    </div>
  );
}

export default Testimoni;