import React from 'react';
import '../../../../App.css';
import Footer from '../../../Footer';
import Content from './Content';
import HeroSection from './HeroSection';
import MoreInfo from './MoreInfo';

function LayananStaff() {
  return (
    <>
      <HeroSection />
      <Content />
      <MoreInfo />
      <Footer />
    </>
  );
}

export default LayananStaff;
