import React from "react";
import "../../Cards.css";
// import CardItem from "./CardItem";
import { Button } from "../../Button";
function MoreInfo() {
  return (
    <>
      <div className="card-container-blog">
        <div class="card-links-blog">
          <div className="card-link-wrapper">
            <div class="card-link-items">
              <div className="card-text-blog">
                <i class="fas fa-comments" style={{ marginRight: 10 }} />
                Punya pertanyaan lain?
              </div>
            </div>
            <div class="card-link-items">
              <div style={{ width: 200 }} />
            </div>
            <div class="card-link-items">
              <div>
                <div className="hero-btns-normal">
                  <Button
                    className="btn"
                    buttonStyle="btn--quartet"
                    buttonSize="btn--small"
                    buttonWeight="bolder"
                  >
                    Hubungi Kami
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default MoreInfo;
