import React from "react";
import "../../../Cards.css";
import { Image } from "react-bootstrap";
function Content() {
  return (
    <>
      <div className="card-container">
        <div class="card-links">
          <div className="card-link-wrapper">
            <div class="card-link-items">
              <h1 className="card-title-mitra">Layanan Administratif</h1>
              <div className="card-title-border"></div>
              <div>
                <Image
                  fluid
                  src="/images/homepage/mitra/building/1.png"
                  rounded
                />
              </div>
            </div>
            <div class="card-link-items">
              <h1 className="card-title-blank"> </h1>
              <div className="more-info-title-mitra">
                Digital Absences Management
              </div>
              <div>
                <div className="more-info-text-content-mitra">
                  Memudahkan kamu dalam melihat dan menghitung jumlah tagihan
                  bulanan dari setiap resident.
                </div>
              </div>
              <div className="more-info-title-mitra">
                Laporan Statistik Building
              </div>
              <div>
                <div className="more-info-text-content-mitra">
                  Pantau statistik building secara keseluruhan dengan data yang
                  lebih lengkap dan mudah dimengerti.
                </div>
              </div>
              <div className="more-info-title-mitra">Laporan Kinerja Staff</div>
              <div>
                <div className="more-info-text-content-mitra">
                  Pantau laporan kinerja hingga atur pembagian tugas dari
                  masing-masing staff.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="card-container">
        <div class="card-links">
          <div className="card-link-wrapper">
            <div class="card-link-items">
              <h1 className="card-title-mitra">Layanan Administratif</h1>
              <div className="card-title-border"></div>
              <div className="more-info-title-mitra">Pembayaran Digital</div>
              <div>
                <div className="more-info-text-content-mitra">
                  Memberikan pilihan bagi penghuni dengan beragam metode
                  pembayaran digital.
                </div>
              </div>
              <div className="more-info-title-mitra">
                Buat Informasi Untuk Penghuni
              </div>
              <div>
                <div className="more-info-text-content-mitra">
                  Buat dan sebarkan informasi untuk penghuni dengan fitur
                  Announcement, lebih cepat lebih mudah.
                </div>
              </div>
              <div className="more-info-title-mitra">Task Management</div>
              <div>
                <div className="more-info-text-content-mitra">
                  Atur setiap laporan kendala hunian dari penghuni dan tugaskan
                  staff terkait dengan segera.
                </div>
              </div>
            </div>
            <div class="card-link-items">
              <h1 className="card-title-blank"> </h1>
              <div className="card-image-right">
                <Image
                  fluid
                  src="/images/homepage/mitra/building/2.png"
                  rounded
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Content;
