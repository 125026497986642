import React from 'react';
import Navbar from './components/Navbar';
import './App.css';
import Home from './components/pages/Home';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import Services from './components/pages/Services';
import Products from './components/pages/Products';
import SignUp from './components/pages/SignUp';
import LayananResident from './components/pages/layanan/layananResident/Home';
import LayananMerchant from './components/pages/layanan/layananMerchant/Home';
import LayananStaff from './components/pages/layanan/layananStaff/Home';
import MitraBuilding from './components/pages/mitra/mitraBuilding/Home';
import MitraMerchant from './components/pages/mitra/mitraMerchant/Home';
import Blog from './components/pages/blog/Home';
import Promo from './components/pages/promo/Home';
import Pengguna from './components/pages/pengguna/Home';
import Kontak from './components/pages/kontak/Home';
import Download from './components/pages/download/Home';

function App() {
  return (
    <>
      <Router>
        <Navbar />
        <Switch>
          <Route path='/' exact component={Home} />
          <Route path='/layanan resident' exact component={LayananResident} />
          <Route path='/layanan merchant' exact component={LayananMerchant} />
          <Route path='/layanan staff' exact component={LayananStaff} />
          <Route path='/mitra building' exact component={MitraBuilding} />
          <Route path='/mitra merchant' exact component={MitraMerchant} />
          <Route path='/blog' exact component={Blog} />
          <Route path='/promo' exact component={Promo} />
          <Route path='/pengguna' exact component={Pengguna} />
          <Route path='/hubungi kami' exact component={Kontak} />
          <Route path='/download' exact component={Download} />
          <Route path='/services' component={Services} />
          <Route path='/products' component={Products} />
          <Route path='/sign-up' component={SignUp} />
        </Switch>
      </Router>
    </>
  );
}

export default App;
