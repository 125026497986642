// import React from 'react';
// import './Button.css';
// import { Link } from 'react-router-dom';

// export function Button() {
//   return (
//     <Link to='sign-up'>
//       <button className='btn'>Sign Up</button>
//     </Link>
//   );
// }

import React from "react";
import "./Button.css";

const STYLES = [
  "btn--primary",
  "btn--secondary",
  "btn--tersier",
  "btn--quartet",
  "btn--secondary--outline",
  "btn--outline",
  "btn--test",
];

const SIZES = ["btn--small", "btn--medium", "btn--large"];

const WEIGHT = ["small", "normal", "bold", "bolder"];

export const Button = ({
  children,
  type,
  onClick,
  buttonStyle,
  buttonSize,
  buttonWeight,
}) => {
  const checkButtonStyle = STYLES.includes(buttonStyle)
    ? buttonStyle
    : STYLES[0];

  const checkButtonSize = SIZES.includes(buttonSize) ? buttonSize : SIZES[0];
  const checkButtonWeight = WEIGHT.includes(buttonWeight)
    ? buttonWeight
    : WEIGHT[0];

  return (
    <button
      className={`btn ${checkButtonStyle} ${checkButtonSize} ${checkButtonWeight}`}
      onClick={onClick}
      type={type}
    >
      {children}
    </button>
  );
};
