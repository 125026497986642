import React from "react";
import "./Cards.css";
// import CardItem from "./CardItem";
import { Card } from "react-bootstrap";
function WhySection() {
  return (
    <>
      <div className="card-container-why">
        <h1 className="card-title-white">Mengapa Yipy?</h1>
        <div class="card-links-mitra">
          <div className="card-link-wrapper-why">
            <div class="card-link-items-why">
              <Card style={{ border: 0, background: "none" }}>
                <Card.Body>
                  <Card.Img
                    style={{ width: 250, margin: "auto" }}
                    variant="top"
                    src="/images/homepage/whySection/integrated-solution.png"
                  />
                  <Card.Title
                    className="mb-4"
                    style={{
                      color: "#3E414C",
                      fontSize: 20,
                      fontWeight: 700,
                      paddingTop: 40,
                      marginBottom: 20,
                    }}
                  >
                    Integrated Solution
                  </Card.Title>
                  <Card.Text
                    className="mt-4"
                    style={{
                      color: "#3E414C",
                      fontSize: 14,
                      fontWeight: 400,
                      paddingLeft: 20,
                      paddingRight: 20,
                    }}
                  >
                    Satu aplikasi pintu utama komunikasi penghuni & pengelola
                    dalam menjawab berbagai kebutuhan tinggal bagi penghuni
                  </Card.Text>
                </Card.Body>
              </Card>
            </div>
            <div class="card-link-items-why">
              <Card style={{ border: 0, background: "none" }}>
                <Card.Body>
                  <Card.Img
                    style={{ width: 250, margin: "auto" }}
                    variant="top"
                    src="/images/homepage/whySection/hospitality.png"
                  />
                  <Card.Title
                    className="mb-4"
                    style={{
                      color: "#3E414C",
                      fontSize: 20,
                      fontWeight: 700,
                      paddingTop: 40,
                      marginBottom: 20,
                    }}
                  >
                    Excellent Hospitality Service
                  </Card.Title>
                  <Card.Text
                    className="mt-4"
                    style={{
                      color: "#3E414C",
                      fontSize: 14,
                      fontWeight: 400,
                      paddingLeft: 20,
                      paddingRight: 20,
                    }}
                  >
                    Meningkatkan standard pelayanan pengelola gedung kepada
                    penghuni yang akan menaikan kualitas kenyaman tempat tinggal
                  </Card.Text>
                </Card.Body>
              </Card>
            </div>
            <div class="card-link-items-why">
              <Card style={{ border: 0, background: "none" }}>
                <Card.Body>
                  <Card.Img
                    style={{ width: 250, margin: "auto" }}
                    variant="top"
                    src="/images/homepage/whySection/realtime.png"
                  />
                  <Card.Title
                    className="mb-4"
                    style={{
                      color: "#3E414C",
                      fontSize: 20,
                      fontWeight: 700,
                      paddingTop: 40,
                      marginBottom: 20,
                    }}
                  >
                    Real Time <br /> Monitorial Process
                  </Card.Title>
                  <Card.Text
                    className="mt-4"
                    style={{
                      color: "#3E414C",
                      fontSize: 14,
                      fontWeight: 400,
                      paddingLeft: 20,
                      paddingRight: 20,
                    }}
                  >
                    Penugasan staff berjalan secara otomatis oleh sistem,
                    sehingga pengelola & penghuni dapat memonitor status
                    pelaporan, penanganan hingga stastus pembayaran secara
                    realtime
                  </Card.Text>
                </Card.Body>
              </Card>
            </div>
            <div class="card-link-items-why">
              <Card style={{ border: 0, background: "none" }}>
                <Card.Body>
                  <Card.Img
                    style={{ width: 250, margin: "auto" }}
                    variant="top"
                    src="/images/homepage/whySection/revenue.png"
                  />
                  <Card.Title
                    className="mb-4"
                    style={{
                      color: "#3E414C",
                      fontSize: 20,
                      fontWeight: 700,
                      paddingTop: 40,
                      marginBottom: 20,
                    }}
                  >
                    More Revenue
                  </Card.Title>
                  <Card.Text
                    className="mt-4"
                    style={{
                      color: "#3E414C",
                      fontSize: 14,
                      fontWeight: 400,
                      paddingLeft: 20,
                      paddingRight: 20,
                    }}
                  >
                    Meningkatnya kulitas layanan kepada penghuni dan tenant akan
                    meningkatkan pula faktor keterhunian serta pendapatan
                    pengelola gedung
                  </Card.Text>
                </Card.Body>
              </Card>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default WhySection;
