import React from "react";
import "./Cards.css";
// import CardItem from "./CardItem";
import { Card, Image } from "react-bootstrap";
import { Button } from "./Button";
function PaymentMethod() {
  return (
    <>
      <div className="card-container-payment">
        <div class="card-links">
          <div className="card-link-wrapper">
            <div class="card-link-items-payment">
              <Card.Body>
                <Card.Text>
                  <Image fluid src="/images/homepage/payment.png" rounded />
                </Card.Text>
              </Card.Body>
            </div>
            <div class="card-link-items-payment">
              <Card.Body>
                <Card.Title
                  style={{
                    fontWeight: 700,
                    fontSize: 38,
                    paddingTop: 25,
                    paddingBottom: 25,
                  }}
                >
                  Beragam Pilihan Metode Pembayaran
                </Card.Title>
                <Card.Text style={{ fontSize: 18 }}>
                  <div className="hero-btns-normal">
                    <Button
                      className="btns"
                      buttonStyle="btn--secondary"
                      buttonSize="btn--large"
                    >
                      Lihat Disini
                    </Button>
                  </div>
                </Card.Text>
              </Card.Body>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default PaymentMethod;
