import React from "react";
import "../../../../App.css";
import "../../../HeroSection.css";

function HeroSection() {
  return (
    <div className="hero-container-mitra-building">
      {/* <video src='/videos/video-1.mp4' autoPlay loop muted /> */}
      <h1>Kelola sistem hunian jadi lebih mudah</h1>
      <p>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ipsum feugiat
        nisi ac volutpat justo, quam nunc vitae. Nam hac faucibus sit netus ut.
        Malesuada ultrices mi sed.
      </p>
    </div>
  );
}

export default HeroSection;
