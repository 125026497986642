import React from "react";
import "../../../Cards.css";
import "../../../../App.css";
import { Image } from "react-bootstrap";
function Benefit() {
  return (
    <>
      <div className="card-container">
        <h1 className="card-title">Kenapa jadi Mitra Merchant Yipy?</h1>
        <div class="card-links">
          <div className="card-link-wrapper">
            <div class="card-link-items-mitra">
              <div
                className="MitraContainer"
                style={{
                  background: `url(/images/homepage/mitra/merchant/b1.png)`,
                }}
              >
                <div className="row">
                  <div className="col">
                    <div className="title-mitra">
                      Pembayaran <br />
                      digital
                    </div>
                    <div className="sub-mitra">
                      Mudahkan pembeli dengan beragam pilihan metode pembayaran
                      digital.
                    </div>
                    <Image
                      fluid
                      src="/images/homepage/mitra/merchant/image/1.png"
                      rounded
                      width="100%"
                      style={{ marginTop: 30 }}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="card-link-items-mitra">
              <div
                className="MitraContainer"
                style={{
                  background: `url(/images/homepage/mitra/merchant/b2.png)`,
                }}
              >
                <div className="row">
                  <div className="col">
                    <div className="title-mitra">
                      Terima laporan secara real time
                    </div>
                    <div className="sub-mitra">
                      Kamu bisa pantau laporan penjualan tokomu secara real
                      time.
                    </div>
                    <Image
                      fluid
                      src="/images/homepage/mitra/merchant/image/2.png"
                      rounded
                      width="100%"
                      style={{ marginTop: 30 }}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="card-link-items-mitra">
              <div
                className="MitraContainer"
                style={{
                  background: `url(/images/homepage/mitra/merchant/b3.png)`,
                }}
              >
                <div className="row">
                  <div className="col">
                    <div className="title-mitra">
                      Jangkau lebih banyak pembeli
                    </div>
                    <div className="sub-mitra">
                      Kamu bisa tingkatin omzet dan jangkau lebih dari 5 ribu
                      pembeli di Yipy
                    </div>
                    <Image
                      fluid
                      src="/images/homepage/mitra/merchant/image/3.png"
                      rounded
                      width="100%"
                      style={{ marginTop: 30 }}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="card-link-items-mitra">
              <div
                className="MitraContainer"
                style={{
                  background: `url(/images/homepage/mitra/merchant/b4.png)`,
                }}
              >
                <div className="row">
                  <div className="col">
                    <div className="title-mitra">Gak perlu keluar budget</div>
                    <div className="sub-mitra">
                      Gak perlu khawatir soal budget, buka toko online di Yipy
                      gratis tanpa biaya apapun.
                    </div>
                    <Image
                      fluid
                      src="/images/homepage/mitra/merchant/image/4.png"
                      rounded
                      width="100%"
                      style={{ marginTop: 30 }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Benefit;
