import React from "react";
import "../../../Cards.css";
import "../../../../App.css";
import { Image } from "react-bootstrap";
function Benefit() {
  return (
    <>
      <div className="card-container">
        <h1 className="card-title">Kenapa jadi Mitra Building Yipy?</h1>
        <div class="card-links">
          <div className="card-link-wrapper">
            <div class="card-link-items-mitra">
              <div
                className="MitraContainer"
                style={{
                  background: `url(/images/homepage/mitra/1.png)`,
                }}
              >
                <div className="row">
                  <div className="col">
                    <div className="title-mitra">Pembayaran</div>
                    <div className="sub-mitra">
                      <b>28+ ribu</b> jumlah resident yang membayar tagihan
                      hunian melalui Yipy.
                    </div>
                    <Image
                      fluid
                      src="/images/homepage/mitra/building/image/1.png"
                      rounded
                      width="100%"
                      style={{ marginTop: 70 }}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="card-link-items-mitra">
              <div
                className="MitraContainer"
                style={{
                  background: `url(/images/homepage/mitra/2.png)`,
                }}
              >
                <div className="row">
                  <div className="col">
                    <div className="title-mitra">Layanan hunian</div>
                    <div className="sub-mitra">
                      Menerima dan menyelesaikan laporan terkait kendala hunian
                      lebih dari <b>6 ribu</b>.
                    </div>
                    <Image
                      fluid
                      src="/images/homepage/mitra/building/image/2.png"
                      rounded
                      width="100%"
                      style={{ marginTop: 70 }}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="card-link-items-mitra">
              <div
                className="MitraContainer"
                style={{
                  background: `url(/images/homepage/mitra/3.png)`,
                }}
              >
                <div className="row">
                  <div className="col">
                    <div className="title-mitra">Announcement</div>
                    <div className="sub-mitra">
                      <b>70+ ribu</b> informasi yang telah diterima oleh
                      resident dari Building Management.
                    </div>
                    <Image
                      fluid
                      src="/images/homepage/mitra/building/image/3.png"
                      rounded
                      width="100%"
                      style={{ marginTop: 70 }}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="card-link-items-mitra">
              <div
                className="MitraContainer"
                style={{
                  background: `url(/images/homepage/mitra/4.png)`,
                }}
              >
                <div className="row">
                  <div className="col">
                    <div className="title-mitra">SOS (Emergency)</div>
                    <div className="sub-mitra">
                      <b>200+</b> keadaan darurat yang berhasil ditangani oleh
                      Building Management menggunakan Yipy.
                    </div>
                    <Image
                      fluid
                      src="/images/homepage/mitra/building/image/4.png"
                      rounded
                      width="100%"
                      style={{ marginTop: 50 }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Benefit;
