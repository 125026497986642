import React from "react";
import { Button } from "../../Button";
import "../../Cards.css";
function Content() {
  return (
    <>
      <div className="card-container-form">
        <h1 className="card-title-form">Punya pertanyaan lain?</h1>
        <p className="card-text-form">
          Silakan isi form di bawah ini dan kami akan membalas secepat mungkin.
        </p>
        <div class="card-links-form">
          <div className="card-link-wrapper">
            <div class="card-link-items-form">
              <form>
                <div>
                  <div className="form-label">Nama Lengkap</div>
                  <input className="form-input" type="text" />
                </div>
                <div>
                  <div className="form-label">Nomor Ponsel</div>
                  <input className="form-input" type="text" />
                </div>
                <div>
                  <div className="form-label">Alamat Email</div>
                  <input className="form-input" type="text" />
                </div>
                <div>
                  <div className="form-label">Nama Lengkap</div>
                  <textarea className="form-input form-input-textarea">
                    Mohon jelaskan pertanyaanmu sedetail mungkin ya, supaya kami
                    bisa dapat membantu kamu lebih baik.
                  </textarea>
                </div>
                <Button
                  className="btn"
                  buttonStyle="btn--secondary"
                  buttonSize="btn--small"
                  buttonWeight="bolder"
                >
                  Kirim
                </Button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Content;
