import React from "react";
import { Image } from "react-bootstrap";
import { Link } from "react-router-dom";
import "../../../App.css";
import "../../HeroSection.css";

function HeroSection() {
  return (
    <div className="hero-container-download">
      {/* <video src='/videos/video-1.mp4' autoPlay loop muted /> */}
      <h1>Belum punya aplikasi Yipy? Yuk, unduh sekarang juga.</h1>
      <p>
        <div className="row">
          <div className="col">
            <Link to="/">
              <Image fluid src="/images/homepage/download/ios.png" rounded height={44} />
            </Link>
          </div>
          <div className="col">
            <Link to="/">
              <Image
                fluid
                src="/images/homepage/download/android.png"
                rounded
                height={43}
              />
            </Link>
          </div>
        </div>
      </p>
    </div>
  );
}

export default HeroSection;
