import React from "react";
import "../../../../App.css";
import "../../../HeroSection.css";

function HeroSection() {
  return (
    <div className="hero-container-layanan-staff">
      {/* <video src='/videos/video-1.mp4' autoPlay loop muted /> */}
      <h1>
        Memberikan Layanan <br /> Terbaik Untuk penghuni
      </h1>
      <p>
        GM BM, BM admin, security, service dan staff lainnya bisa berkomunikasi
        dalam aplikasi untuk memberikan layanan terbaik bagi penghuni
      </p>
    </div>
  );
}

export default HeroSection;
