import React from "react";
import "../../../Cards.css";

function Separator() {
  return (
    <div className="card-container-separator">
      <h1 className="more-info-title-white">
        Kami berkomitmen untuk terus menjadi <br />
        #SahabatHunianmu
      </h1>
    </div>
  );
}

export default Separator;
