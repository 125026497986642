import React from 'react';
import '../../../../App.css';
import Footer from '../../../Footer';
import Benefit from './Benefit';
import Content from './Content';
import HeroSection from './HeroSection';
import MoreInfo from './MoreInfo';
import Separator from './Separator';
import Testimoni from './Testimoni';

function MitraBuilding() {
  return (
    <>
      <HeroSection />
      <Benefit />
      <Separator />
      <Content />
      <Testimoni />
      <MoreInfo />
      <Footer />
    </>
  );
}

export default MitraBuilding;
