import React from "react";
import "../../Cards.css";
function Content() {
  return (
    <>
      <div className="card-container">
        <h1 className="card-title-kontak">
          Tidak menemukan jawaban dari kendalamu?
        </h1>
        <p className="card-text-kontak">
          Yuk, hubungi kami melalui call center, email atau kirim laporan.
        </p>
        <div class="card-links">
          <div className="card-link-wrapper">
            <div class="card-link-items-kontak">
              <div>
                <div className="more-info-text-kontak">
                  Ikuti akun resmi media sosial Yipy untuk tahu info terbaru
                </div>
              </div>
              <div className="icon-container">
                <div className="row">
                  <div className="col more-info-ico-kontak">
                    <i class="fab fa-instagram" />
                  </div>
                  <div className="col more-info-text-ico-kontak">@yipy_id</div>
                </div>
              </div>
              <div className="icon-container">
                <div className="row">
                  <div className="col more-info-ico-kontak">
                    <i class="fab fa-facebook-square" />
                  </div>
                  <div className="col more-info-text-ico-kontak">@yipy</div>
                </div>
              </div>
              <div className="icon-container">
                <div className="row">
                  <div className="col more-info-ico-kontak">
                    <i class="fab fa-linkedin" />
                  </div>
                  <div className="col more-info-text-ico-kontak">Yipy Apps</div>
                </div>
              </div>
            </div>
          </div>
          <div className="card-link-wrapper">
            <div class="card-link-items">
              <div>
                <div className="more-info-text-kontak">
                  Layanan Sales & Support
                </div>
              </div>
              <div className="icon-container">
                <div className="row">
                  <div className="col more-info-ico-kontak">
                    <i class="fas fa-phone-alt" />
                  </div>
                  <div className="col more-info-text-ico-kontak">
                    +62 21 3006-1546
                  </div>
                </div>
              </div>
              <div className="icon-container">
                <div className="row">
                  <div className="col more-info-ico-kontak">
                    <i class="fas fa-envelope" />
                  </div>
                  <div className="col more-info-text-ico-kontak">
                    sales@yipy.id
                  </div>
                </div>
              </div>
              <div className="icon-container">
                <div className="row">
                  <div className="col more-info-ico-kontak">
                    <i class="fas fa-envelope" />
                  </div>
                  <div className="col more-info-text-ico-kontak">
                    customercare@yipy.id
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="card-link-wrapper">
            <div class="card-link-items">
              <div>
                <div className="more-info-text-kontak">
                  Layanan Sales & Support
                </div>
              </div>
              <div className="icon-container">
                <div className="row">
                  <div className="col more-info-ico-kontak">
                    <i class="fas fa-map-marker-alt" />
                  </div>
                  <div className="col info-text-location-kontak">
                    Business Park Kebon Jeruk
                  </div>
                </div>
              </div>
              <div className="icon-container">
                <div className="row">
                  <div className="col more-info-ico-kontak"></div>
                  <div className="col info-text-location-desc-kontak">
                    Blok D2 No. 3, Jl. Raya Meruya Ilir No. 88, Kel. Meruya
                    Utara, Kec. Kembangan, Jakarta Barat 11620
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Content;
