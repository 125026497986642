import React from "react";
import "../../../App.css";
import "../../HeroSection.css";

function HeroSection() {
  return (
    <div className="hero-container-promo">
      {/* <video src='/videos/video-1.mp4' autoPlay loop muted /> */}
      <h1>Serbu promonya disini!</h1>
      <p>Temukan berbagai promo dan penawaran menarik lainnya dari Yipy</p>
    </div>
  );
}

export default HeroSection;
