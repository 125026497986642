import React from "react";
import "../../../App.css";
import "../../HeroSection.css";

function HeroSection() {
  return (
    <div className="hero-container-blog">
      {/* <video src='/videos/video-1.mp4' autoPlay loop muted /> */}
      <h1>Berita & Tips</h1>
      <p>
        <input className="FormInput" type="text" placeholder="Cari berbagai macam artikel soal Yipy disini" />
      </p>
    </div>
  );
}

export default HeroSection;
