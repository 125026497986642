import React from "react";
import "../../../../App.css";
import "../../../HeroSection.css";

function HeroSection() {
  return (
    <div className="hero-container-layanan-resident">
      {/* <video src='/videos/video-1.mp4' autoPlay loop muted /> */}
      <h1>Jadi Lebih Mudah <br /> Dengan Aplikasi Yipy</h1>
      <p>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Et dapibus
        risus euismod vitae lorem libero ornare lorem. Faucibus fringilla tempus
        faucibus..
      </p>
    </div>
  );
}

export default HeroSection;
