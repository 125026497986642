import React from "react";
import "../../../App.css";
import "../../HeroSection.css";

function HeroSection() {
  return (
    <div className="hero-container-pengguna">
      {/* <video src='/videos/video-1.mp4' autoPlay loop muted /> */}
      <h1>Jadi Lebih Baik Bareng #SahabatHunian</h1>
    </div>
  );
}

export default HeroSection;
