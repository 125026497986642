import React from 'react';
import '../../../App.css';
import Footer from '../../Footer';
import Content from './Content';
import HeroSection from './HeroSection';
import MoreInfo from './MoreInfo';

function Pengguna() {
  return (
    <>
      <HeroSection />
      <Content />
      <MoreInfo />
      <Footer />
    </>
  );
}

export default Pengguna;
