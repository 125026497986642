import React from "react";
import "../../../Cards.css";
import { Card, Image } from "react-bootstrap";
function Cards() {
  return (
    <>
      <div className="card-container">
        <div class="card-links">
          <div className="card-link-wrapper">
            <div class="card-link-items">
              <Card.Body>
                <Card.Text>
                  <Image
                    fluid
                    src="/images/homepage/layanan/merchant/placeholder.png"
                    rounded
                  />
                </Card.Text>
              </Card.Body>
            </div>
            <div class="card-link-items">
              <Card.Body>
                <Card.Title className="more-info-title">
                  Langkah Mudah Buka <br />
                  Toko Online di Yipy
                </Card.Title>
                <Card.Text className="more-info-text-content">
                  <div>
                    <div
                      style={{
                        paddingBottom: 30,
                        paddingRight: 100,
                        fontWeight: 500,
                        alignContent: "center",
                        display: "flex",
                      }}
                    >
                      <Image
                        style={{
                          width: 32,
                          height: 30,
                          marginRight: 20,
                          marginTop: 6,
                        }}
                        fluid
                        src="/images/homepage/layanan/merchant/star.png"
                      />{" "}
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                      Sit lectus justo vulputate posuere.
                    </div>
                    <div
                      style={{
                        paddingBottom: 30,
                        paddingRight: 100,
                        fontWeight: 500,
                        alignContent: "center",
                        display: "flex",
                      }}
                    >
                      <Image
                        style={{
                          width: 32,
                          height: 30,
                          marginRight: 20,
                          marginTop: 6,
                        }}
                        fluid
                        src="/images/homepage/layanan/merchant/star.png"
                      />{" "}
                      Mattis viverra vitae, metus amet. Pretium, pulvinar amet,
                      enim adipiscing lorem orci.
                    </div>
                    <div
                      style={{
                        paddingBottom: 30,
                        paddingRight: 100,
                        fontWeight: 500,
                        alignContent: "center",
                        display: "flex",
                      }}
                    >
                      <Image
                        style={{
                          width: 32,
                          height: 30,
                          marginRight: 20,
                          marginTop: 6,
                        }}
                        fluid
                        src="/images/homepage/layanan/merchant/star.png"
                      />{" "}
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                      Netus a, scelerisque velit lectus augue mattis sed sed.
                    </div>
                    <div
                      style={{
                        paddingBottom: 30,
                        paddingRight: 100,
                        fontWeight: 500,
                        alignContent: "center",
                        display: "flex",
                      }}
                    >
                      <Image
                        style={{
                          width: 32,
                          height: 30,
                          marginRight: 20,
                          marginTop: 6,
                        }}
                        fluid
                        src="/images/homepage/layanan/merchant/star.png"
                      />{" "}
                      Mattis viverra vitae, metus amet. Pretium, pulvinar amet,
                      enim adipiscing lorem orci.
                    </div>
                    <div
                      style={{
                        paddingBottom: 30,
                        paddingRight: 100,
                        fontWeight: 500,
                        alignContent: "center",
                        display: "flex",
                      }}
                    >
                      <Image
                        style={{
                          width: 32,
                          height: 30,
                          marginRight: 20,
                          marginTop: 6,
                        }}
                        fluid
                        src="/images/homepage/layanan/merchant/star.png"
                      />{" "}
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                      Sit lectus justo vulputate posuere.
                    </div>
                  </div>
                </Card.Text>
              </Card.Body>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Cards;
