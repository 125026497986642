import React from "react";
import "../App.css";
import { Button } from "./Button";
import "./HeroSection.css";

function HeroSection() {
  return (
    <div className="hero-container">
      {/* <video src='/videos/video-1.mp4' autoPlay loop muted /> */}
      <h1>Beragam Solusi Hunian Dalam Satu Aplikasi</h1>
      <p>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Et dapibus
        risus euismod vitae lorem libero ornare lorem. Faucibus fringilla tempus
        faucibus..{" "}
      </p>
      <div className="hero-btns">
        <Button
          className="btns"
          buttonStyle="btn--primary"
          buttonSize="btn--large"
        >
          Demo Gratis
        </Button>
      </div>
    </div>
  );
}

export default HeroSection;
