import React from "react";
import "../../../Cards.css";
// import CardItem from "./CardItem";
import { Image } from "react-bootstrap";
import { Button } from "../../../Button";
function MoreInfo() {
  return (
    <>
      <div className="card-container-mitra-section">
        <div class="card-links">
          <div className="card-link-wrapper">
            <div class="card-link-items">
              <div>
                <Image
                  fluid
                  src="/images/homepage/mitra/building/blank.png"
                  rounded
                />
              </div>
            </div>
            <div class="card-link-items">
              <div>
                <div className="more-info-title-white">
                  Tertarik untuk gabung jadi <br />
                  Mitra Yipy?
                </div>
                <div>
                  <div className="more-info-text-white">
                    Jadwalkan demo dan dapatkan informasi lebih lanjut.
                  </div>
                  <div className="hero-btns-normal">
                    <Button
                      className="btn"
                      buttonStyle="btn--tersier"
                      buttonSize="btn--small"
                      buttonWeight="bolder"
                    >
                      Jadwalkan Demo
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default MoreInfo;
